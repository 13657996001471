import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

function ErrorMessage(props) {
  const { message } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} data-testid="alert-message-box">
      <Alert severity="error">{ message }</Alert>
    </Box>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
