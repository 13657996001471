import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function LoadingIcon() {
  return (
    <div className="spinner">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} data-testid="network-call-spinner">
        <CircularProgress />
      </Box>
    </div>
  );
}

export default LoadingIcon;
