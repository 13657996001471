import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';
import { setConsultantData, setStatus } from './consultantDataSlice';

function ConsultantDataLoadingService() {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const request = {
    scopes: ['User.Read', 'email'],
    account: accounts[0],
  };
  const getConsultantData = (response) => {
    const axiosClient = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: { Authorization: `Bearer ${response.idToken}` },
    });
    axiosClient.get('/api/getSfData')
      .then((res) => {
        const consultants = res.data.consultant[0];
        if (!consultants) {
          dispatch(setStatus('notFound'));
        } else {
          dispatch(setConsultantData(consultants));
          dispatch(setStatus('idle'));
        }
      })
      .catch(() => {
        dispatch(setStatus('error'));
      });
  };

  useEffect(() => {
    if (!account) {
      return;
    }
    dispatch(setStatus('loading'));
    instance.acquireTokenSilent(request)
      .then((response) => {
        getConsultantData(response);
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request).then((response) => {
            getConsultantData(response);
          });
        }
      });
  }, [dispatch]);
}

export default ConsultantDataLoadingService;
