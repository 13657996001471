import React from 'react';
import { useSelector } from 'react-redux';
import LoadingIcon from '../features/loading/LoadingIcon';
import ConsultantTable from '../features/consultantContainer/consultantTable';
import { errors, status } from '../consts';
import ErrorMessage from '../features/errorMessage/ErrorMessage';

function Body() {
  const loadStatusSelector = (state) => state.consultantPortal.status;
  const loadStatus = useSelector(loadStatusSelector);
  return (
    <div className="container-app">
      {(() => {
        switch (loadStatus) {
          case status.LOADING:
            return <LoadingIcon />;
          case status.IDLE:
            return <ConsultantTable />;
          case status.ERROR:
            return <ErrorMessage message={errors.ERROR_FAILED} />;
          case status.NOT_FOUND:
            return <ErrorMessage message={errors.NOT_FOUND_BY_EMAIL + errors.REACH_LOS_MSG} />;
          default:
            return null;
        }
      })()}
    </div>
  );
}

export default Body;
