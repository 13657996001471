import * as React from 'react';
import { Card } from '@mui/material';
import AboutMe from '../aboutme/AboutMe';
import ConsultantBio from '../consultant/ConsultantBio';
import CurrentEngagement from '../currentEngagement/CurrentEngagement';
import UpcomingOpportunities from '../upcomingOpportunities/UpcomingOpportunities';
import { general } from '../../consts';

function ConsultantTable() {
  return (
    <div data-testid="consultant-table">
      <h1 className="about-me-header" data-testid="about-me-header">
        {general.ABOUT_ME}
      </h1>
      <Card className="box-raised" raised>
        <AboutMe />
        <ConsultantBio />
        <CurrentEngagement />
        <UpcomingOpportunities />
      </Card>
    </div>
  );
}

export default ConsultantTable;
