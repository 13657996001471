export const general = {
  ABOUT_ME: 'About Me',
};

export const tooltips = {
  BIO_REGION_INFOTIP: 'The Daugherty region you are aligned with.',
  BIO_TITLE_INFOTIP: 'Your Daugherty job title.',
  BIO_PRIMARY_ROLE_INFOTIP: 'Your primary Daugherty delivery role.',
  BIO_ROLES_INFOTIP: 'All Daugherty delivery roles you have received recognition for.',
  BIO_LOS_LEADER_INFOTIP: 'Your assigned LOS Leader.',
  BIO_SKILLSET_INFOTIP: 'The list of primary and secondary skills and preferences for upcoming work that leaders will use to align you to an engagement. This list may not be exhaustive or represent all the skills in your resume.  For changes, please talk to your LOS Leader',
  UPCOMING_OPPORTUNITIES_POTENTIAL_START_INFOTIP: 'The tentatively planned start date for the engagement. This date may change multiple times as we develop the opportunity with the client.',
  UPCOMING_OPPORTUNITIES_OWNER_INFOTIP: 'The owner of this opportunity and typically the person working to develop the opportunity with the client. This is a good person to reach out to for questions.',
  CUR_ENGAGE_TEAM_MANAGER: 'The Team Manager assigned to the consultant for this engagement.  This sometimes may get out of sync.  If you feel a correction is needed please contact your CM or LOS Lead',
  CUR_ENGAGE_CLIENT_MANAGER: 'The Client Manager assigned to the consultant for this engagement.  This sometimes may get out of sync.  If you feel a correction is needed please contact your CM or LOS Lead',
  CUR_ENGAGE_START_DATE: 'Contractual start date for the engagement.',
  CUR_ENGAGE_END_DATE: 'Contractual end date for the engagement. This date will change if the engagement is extended.',
  TEST: 'The Stage of an opportunity identifies where we are in the process of developing the opportunity with the \n client.',
};

export const errors = {
  NOT_FOUND_BY_EMAIL: 'Sorry, we can\'t find any information related to the e-mail address you provided. ',
  REACH_LOS_MSG: 'Please reach out to your LOS lead to update your email information.',
  ERROR_FAILED: 'Failed to retrieve consultant information. Reach out to your LOS lead if the issue persists.',
};

export const status = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  NOT_FOUND: 'notFound',
};
